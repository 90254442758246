import { Text } from "@mantine/core";
import EditableField from "./EditableField";

const Element = ({ id, name, type, value, position, onChange, groupId }) => {
    const getHtmlElement = () => {
        if(type === "Address") {
            return "address";
        } else {
            return "span";
        }
    }

    const dateValue = value && type === "Date Picker" ? new Date(value).toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' }) : null;

    return(
        <>
            <Text c="dimmed" size="xs" className="text-uppercase tif-ls-50" mt={position !== 0 ? "15px" : "0"}>{name}</Text>
            <EditableField element={getHtmlElement()} name="field-values_value" id={id} className="tif-mb-0" size="xl" required={false} onSave={onChange} groupId={groupId} fieldName={name} type={type} hiddenValue={type === "Password" ? value : null} value={type === "Yes/No" ? value === "true" : value} clearable>
                {
                    (type === "Date Picker" && dateValue) ?
                        (dateValue)
                    :
                    (type === "Yes/No" && value) ?
                        (value === "true" ? "Yes" : (value === null ? "(empty)" : "No"))
                    :
                    value ?
                        (value)
                    :
                        <Text c="dimmed" size="md">(empty)</Text>
                }
            </EditableField>
        </>
    );
};

export default Element;