const excludedAccounts = [
    "Think it First Co",
];

export const getExcludedAccountsString = (excludeAccounts, singleFilter) => {
    if(excludeAccounts === "false" || excludeAccounts === "null") {
        return "";
    } else {
        return encodeURIComponent(`${ !singleFilter ? ' AND ' : '' }account NOT IN (${ excludedAccounts.length > 1 ? excludedAccounts.map(account => `"${account}"`).join(', ') : `"${ excludedAccounts[0] }"` })`)
    }
};

export const checkUser = async () => {
    return await fetch(`${ process.env.REACT_APP_API_URL }/api/authenticate-user`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
        credentials: "include",
    }).then(async res => await res.json()).catch(error => {
        console.error(error);
        return false;
    });
};

export const reAuthenticateUser = async () => {
    return await fetch(`${ process.env.REACT_APP_API_URL }/api/re-authenticate-user`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        credentials: "include",
    }).then(async res => await res.json()).catch(error => {
        console.error(error);
        return false;
    });
};

export const loginUser = async (email, password) => {
    const response = await fetch(`${ process.env.REACT_APP_API_URL }/api/login`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, password }),
        credentials: "include",
    });
    
    const data = await response.json();
    
    return data;
};

export const register = async (key, password) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/api/register`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({ key, password }),
    });

    const data = await response.json();
    
    return data;
};

export const sendPasswordResetEmail = async (email) => {
    const response = await fetch(`${ process.env.REACT_APP_API_URL }/api/send-password-reset-email`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({ email }),
    });
    
    const data = await response.json();
    
    return data;
};

export const resetPassword = async (token, newPassword) => {
    const response = await fetch(`${ process.env.REACT_APP_API_URL }/api/reset-password`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({ token, newPassword }),
    });
    
    const data = await response.json();
    
    return data;
};

export const addUser = async (name, email, role) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/api/add-user`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            name,
            email,
            role
        }),
        credentials: "include",
    });

    const data = await response.json();
    
    return data;
};

export const getStatistics = async excludeAccounts => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/api/statistics/${ excludeAccounts }`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
        credentials: "include",
    });

    const data = await response.json();
    
    return data;
};

export const logoutUser = async () => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/api/logout`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
        credentials: "include",
    });

    const data = await response.json();
    
    return data;
};

export const getUser = async id => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/api/user`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
        credentials: "include",
    });

    const data = await response.json();
    
    return data;
};

export const changePassword = async (id, oldPassword, newPassword) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/api/user/${ id }/change-password`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            oldPassword,
            newPassword
        }),
        credentials: "include",
    });

    const data = await response.json();
    
    return data;
};

export const deleteUser = async id => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/api/user/${ id }/delete`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        credentials: "include",
    });

    const data = await response.json();
    
    return data;
};

export const getTempoToken = async authorizationCode => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/api/tempo/get-token`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({ authorizationCode }),
    });

    const data = await response.json();
    
    return data;
};

export const saveTempoAccessToken = async tokens => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/api/tempo/save-token`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify({ tokens }),
    });

    const data = await response.json();
    
    return data;
};

export const getTempoUserWorklogs = async (jiraAccountId, from, to) => {
    if(!from && !to) {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/tempo/user-worklogs/${ jiraAccountId }`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
            credentials: "include",
        });
    
        const data = await response.json();
        
        return data;
    } else {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/tempo/user-worklogs/${ jiraAccountId }/${ from }/${ to }`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
            credentials: "include",
        });
    
        const data = await response.json();
        
        return data;
    }
};

export const getJiraIssues = async (issueIds, excludeAccounts) => {
    if(issueIds?.length) {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/jira/issues/${ issueIds.join(', ') }/${ excludeAccounts }`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
            credentials: "include",
        });
    
        const data = await response.json();
        
        return data;
    } else {
        return { success: true, data: { issues: [] } };
    }
};

export const getUserTicketCounts = async (jiraAccountId, excludeAccounts) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/api/jira/user-ticket-counts/${ jiraAccountId }/${ excludeAccounts }`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
        credentials: "include",
    });

    const data = await response.json();
    
    return data;
};

export const getMonthWorklogs = async date => {
    const year = date.getFullYear();
    const month = date.getMonth();

    const firstDay = new Date(year, month, 1);
    const lastDay = new Date(year, month + 1, 0);

    const formatDate = d => d.toISOString().split('T')[0];
    const firstDayStr = formatDate(firstDay);
    const lastDayStr = formatDate(lastDay);

    const response = await fetch(`${process.env.REACT_APP_API_URL}/api/tempo/month-worklogs/${firstDayStr}/${lastDayStr}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
        credentials: "include",
    });

    const data = await response.json();
    
    return data;
};

export const getAllUsers = async () => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/api/users`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
        credentials: "include",
    });

    const data = await response.json();
    
    return data;
};

export const saveJiraApiToken = async token => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/api/jira/save-token`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify({ token }),
    });

    const data = await response.json();
    
    return data;
};

export const updateUserTimeSettings = async (userId, settings) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/api/user/save-time-settings/${ userId }`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            settings
        }),
        credentials: "include",
    });

    const data = await response.json();
    
    return data;
};

export const updateUserTimeOffSettings = async (userId, ptoData) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/api/user/save-time-off-settings/${ userId }`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            data: ptoData
        }),
        credentials: "include",
    });

    const data = await response.json();
    
    return data;
};

export const getUserSettings = async userId => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/api/user/settings/${ userId }`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
        credentials: "include",
    });

    const data = await response.json();
    
    return data;
};

export const getHoursAvailable = async () => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/api/hours-available`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
        credentials: "include",
    });

    const data = await response.json();
    
    return data;
};

export const getUserOpenTickets = async (jiraAccountId, excludeAccounts) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/api/jira/user-open-tickets/${ jiraAccountId }/${ excludeAccounts }`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
        credentials: "include",
    });

    const data = await response.json();
    
    return data;
};

export const getUserCustomerReviewTickets = async (jiraAccountId, excludeAccounts) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/api/jira/user-customer-review-tickets/${ jiraAccountId }/${ excludeAccounts }`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
        credentials: "include",
    });

    const data = await response.json();
    
    return data;
};

export const getUserInProgressTickets = async (jiraAccountId, excludeAccounts) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/api/jira/user-in-progress-tickets/${ jiraAccountId }/${ excludeAccounts }`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
        credentials: "include",
    });

    const data = await response.json();
    
    return data;
};

export const getUserDoneTickets = async (jiraAccountId, excludeAccounts) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/api/jira/user-done-tickets/${ jiraAccountId }/${ excludeAccounts }`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
        credentials: "include",
    });

    const data = await response.json();
    
    return data;
};

export const saveUserSettings = async (jiraAccountId, userData) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/api/user/save-settings/${ jiraAccountId }`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            data: userData
        }),
        credentials: "include",
    });

    const data = await response.json();
    
    return data;
};

export const deleteTimeOff = async (userId, pto) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/api/user/time-off/delete/${ userId }`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            pto
        }),
        credentials: "include",
    });

    const data = await response.json();
    
    return data;
};

export const getUserEmptyTimeEstimateTickets = async (jiraAccountId, excludeAccounts) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/api/jira/user-empty-time-estimate-tickets/${ jiraAccountId }/${ excludeAccounts }`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
        credentials: "include",
    });

    const data = await response.json();
    
    return data;
};

export const getUserWhatsHotTickets = async (jiraAccountId, excludeAccounts) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/api/jira/user-whats-hot-tickets/${ jiraAccountId }/${ excludeAccounts }`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
        credentials: "include",
    });

    const data = await response.json();
    
    return data;
};

export const getUserHighPriorityTickets = async (jiraAccountId, excludeAccounts) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/api/jira/user-high-priority-tickets/${ jiraAccountId }/${ excludeAccounts }`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
        credentials: "include",
    });

    const data = await response.json();
    
    return data;
};

export const getAllClients = async () => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/api/clients`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
        credentials: "include",
    });

    const data = await response.json();
    
    return data;
};

export const getClient = async id => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/api/client/${id}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
        credentials: "include",
    });

    const data = await response.json();
    
    return data;
};

export const updateField = async (id, table, column, value) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/api/${table}/update/${column}/${id}`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({ value }),
        credentials: "include",
    });

    const data = await response.json();
    
    return data;
};

export const createField = async (table, column, value, clientType) => {
    const bodyData = {
        value
    };

    if(clientType) {
        bodyData.clientType = clientType;
    }
    
    const response = await fetch(`${process.env.REACT_APP_API_URL}/api/${table}/create/${column}`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(bodyData),
        credentials: "include",
    });

    const data = await response.json();
    
    return data;
};

export const saveClientGeneralSettings = async (id, data) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/api/client/update/general/${id}`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({ data }),
        credentials: "include",
    });

    const result = await response.json();
    
    return result;
};

export const saveFieldGroup = async (id, name, fields) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/api/client/create-field-group/${id}`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({ name, fields }),
        credentials: "include",
    });

    const data = await response.json();
    
    return data;
};

export const getFieldGroupTabs = async (client_id, client_type) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/api/client/get-field-group-tabs/${client_id}/${client_type}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
        credentials: "include",
    });

    const data = await response.json();
    
    return data;
};

export const getFieldGroup = async (client_id, groupId) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/api/client/get-field-group/${client_id}/${groupId}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
        credentials: "include",
    });

    const data = await response.json();
    
    return data;
};

export const getFieldGroupValues = async client_id => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/api/client/get-field-group-values/${client_id}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
        credentials: "include",
    });

    const data = await response.json();
    
    return data;
};

export const getPastValues = async date => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/api/statistics/get-past-values/${ date.toISOString() }`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
        credentials: "include",
    });

    const data = await response.json();
    
    return data;
};

export const saveFieldGroupValues = async (clientId, groupId, fieldValues) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/api/save-field-group-values/${clientId}/${groupId}`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({ fieldValues }),
        credentials: "include",
    });

    const data = await response.json();
    
    return data;
};

export const getDefaultFieldGroups = async () => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/api/get-default-field-groups`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
        credentials: "include",
    });

    const data = await response.json();
    
    return data;
};

export const saveDefaultFieldGroups = async groups => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/api/save-default-field-groups`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({ groups }),
        credentials: "include",
    });

    const data = await response.json();
    
    return data;
};

export const deleteDefaultFieldGroup = async id => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/api/delete-default-field-group/${id}`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        credentials: "include",
    });

    const data = await response.json();
    
    return data;
};

export const getClientDetails = async id => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/api/get-client-details/${id}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
        credentials: "include",
    });

    const data = await response.json();
    
    return data;
};

export const createClient = async (name, key, type) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/api/clients/create`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({ name, key, type }),
        credentials: "include",
    });

    const data = await response.json();
    
    return data;
};

export const getUserCodeReviewTickets = async (jiraAccountId, excludeAccounts) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/api/jira/user-code-review-tickets/${ jiraAccountId }/${ excludeAccounts }`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
        credentials: "include",
    });

    const data = await response.json();
    
    return data;
};

export const archiveClient = async (id, data) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/api/client/update/archive/${id}`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({ data }),
        credentials: "include",
    });

    const result = await response.json();
    
    return result;
};

export const saveClientContacts = async (id, contacts) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/api/client/update/contacts/${id}`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({ contacts }),
        credentials: "include",
    });

    const result = await response.json();
    
    return result;
};

export const getUserInternalReviewTickets = async (jiraAccountId, excludeAccounts) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/api/jira/user-internal-review-tickets/${ jiraAccountId }/${ excludeAccounts }`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
        credentials: "include",
    });

    const data = await response.json();
    
    return data;
};

export const getUserAlerts = async () => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/api/user/alerts`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
        credentials: "include",
    });

    const data = await response.json();
    
    return data;
};

export const markAlertComplete = async alertId => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/api/alert/mark-alert-complete`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify({ alertId }),
    });

    const data = await response.json();
    
    return data;
};