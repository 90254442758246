import { useEffect, useState } from 'react';
import { Accordion, Button, Group} from '@mantine/core';
import { saveDefaultFieldGroups } from '../../data/dataApi';
import DefaultFieldGroup from './DefaultFieldGroup';
import { showSuccessNotification, showUnexpectedErrorNotification } from '../helpers';
import { useUserState } from './UserStateContext';

const DefaultFieldGroups = ({ groups, onChange, type }) => {
    const initialGroup = {
        name: "",
        fields: [],
        newGroup: true,
        edited: true
    };

    const { globalEditingMode } = useUserState();
    const [loading, setLoading] = useState(false);

    const handleSave = async () => {
        setLoading(true);
        const response = await saveDefaultFieldGroups(groups);

        if(response.success) {
            onChange(prevState => {
                const newGroups = [...prevState];
                for(const group of newGroups) {
                    for(const field of group.fields) {
                        field.newField = false;
                    }
                }
                return newGroups;
            });
            showSuccessNotification("Field groups saved successfully");
        } else {
            showUnexpectedErrorNotification();
        }
        setLoading(false);
    };

    return (
        <div className="d-flex flex-column flex-grow-1">
            <p>Groups</p>

            <Accordion mb="20" variant="contained">
                {
                    groups.map((group, groupIndex) => (
                        <DefaultFieldGroup group={group} position={groupIndex} key={groupIndex} onGroupUpdate={onChange} type={type} />
                    ))
                }
            </Accordion>

            <Button variant="light" onClick={() => onChange(prevState => ([...prevState, initialGroup]))} mb="30" disabled={globalEditingMode}>Add Group</Button>

            <Group justify="right" mt="auto">
                <Button onClick={handleSave} variant="outline" loading={loading}>
                    Save
                </Button>
            </Group>
        </div>
    );
};

export default DefaultFieldGroups;