import {
    getStatistics,
    getMonthWorklogs,
    getPastValues,
} from '../../data/dataApi';
import { useEffect, useRef } from 'react';
import {
    Group,
    SimpleGrid,
    Text,
    Skeleton,
    Switch,
    Progress,
    Box,
    Paper,
    NumberFormatter,
} from '@mantine/core';
import {
    IconClockCheck,
    IconReceipt2,
    IconCurrentLocation,
    IconClockQuestion,
    IconClockPlay,
    IconClockSearch,
    IconClockStop,
    IconCirclePlus,
    IconQuestionMark,
    IconUserOff,
    IconFlame,
    IconCalendarMonth,
    IconDeviceAnalytics,
} from '@tabler/icons-react';
import { Link } from 'react-router-dom';
import { isCurrentMonthAndYear, listTickets } from '../helpers';
import Card from './Card';
import {
    getHoursAvailable,
    getExcludedAccountsString
} from '../../data/dataApi';
import { useUserState } from './UserStateContext';
import {
    getNetworkDays,
    today,
    firstMonthDay,
    filteredOutsideMonth
} from '../helpers';
import PTOCalendarDay from './PTOCalendarDay';
import UpdateStatus from './UpdateStatus';
import { MonthPickerInput } from '@mantine/dates';

const Statistics = ({ excludeAccounts, onExcludeAccountsChange }) => {
    const { statistics, setStatistics, users, currentMonthUsersTimeOff, setCurrentMonthUsersTimeOff, refresh, setRefresh, statsDate, setStatsDate } = useUserState();
    const initialMount = useRef(true);

    let tracking = statistics ? Math.round((((statistics.time.billed) * (getNetworkDays() / getNetworkDays(firstMonthDay, today))) / statistics.time.hoursAvailable) * 100) : 0;

    if(statistics && !isCurrentMonthAndYear(statsDate)) {
        tracking = Math.round((statistics.time.billed / statistics.time.hoursAvailable) * 100);
    }

    const timeStatsData = statistics ? [
        { title: 'Billable Target', icon: IconClockCheck, value: statistics.time.hoursAvailable },
        { title: 'Billed', icon: IconReceipt2, value: statistics.time.billed },
        { title: 'Tracking', icon: IconCurrentLocation, value: `${tracking}%`, progress: tracking },
    ] : [];

    const { open, inProgress, customerReview, total } = statistics ? statistics.ticketCount : { open: 0, inProgress: 0, customerReview: 0, total: 0 };

    const ticketCountsStatsData = statistics ? [
        { label: 'Open', icon: IconClockQuestion, count: open, part: Math.round((open / total) * 100), color: "#40c057" },
        { label: 'In Progress', icon: IconClockPlay, count: inProgress, part: Math.round((inProgress / total) * 100), color: "#228be6" },
        { label: 'Customer Review', icon: IconClockSearch, count: customerReview, part: Math.round((customerReview / total) * 100), color: "#fd7e14" },
    ] : [];

    const timeStats = timeStatsData.map((stat, index) => {
        return (
            <Card title={stat.title} Icon={stat.icon} progress={stat?.progress} key={index} isStat>
                {
                    stat?.progress ?
                        <Text fw={700} size="xl">
                            {stat.value}
                        </Text>
                        :
                        <Group align="start" mt={25}>
                            <p className="value tif-font-xl tif-mb-10">{stat.value}</p>
                        </Group>
                }
            </Card>
        );
    });

    const segments = ticketCountsStatsData.map((segment) => (
        <Progress.Section value={segment.part} color={segment.color} key={segment.color}>
            {segment.part > 10 && <Progress.Label>{segment.part}%</Progress.Label>}
        </Progress.Section>
    ));

    const descriptions = ticketCountsStatsData.map((stat) => (
        <Box key={stat.label} style={{ borderBottomColor: stat.color }} className="stat">
            <Text tt="uppercase" fz="sm" c="dimmed" fw={700}>
                {stat.label}
            </Text>

            <Group justify="space-between" align="flex-end" gap={0}>
                <Text fw={700}>{stat.count}</Text>
                <Text c={stat.color} fw={700} size="md" className="statCount">
                    {stat.part}%
                </Text>
            </Group>
        </Box>
    ));

    const PTOCalendar = () => {
        const now = statsDate;
        const year = now.getFullYear();
        const month = now.getMonth();

        const daysInMonth = new Date(year, month + 1, 0).getDate();

        const daysArray = Array.from({ length: daysInMonth }, (_, i) => i + 1);

        return (
            <Group gap="5px" className="pto-calendar" align="flex-start">
                {daysArray.map((day) => 
                    <PTOCalendarDay day={day} key={day} data={currentMonthUsersTimeOff} />
                )}
            </Group>
        );
    };

    const getCurrentDate = () => {
        const now = new Date();
        now.setHours(0, 0, 0, 0);
    
        return now;
    };

    const isCurrentMonth = (date) => {
        const now = new Date();
        return date.getFullYear() === now.getFullYear() && date.getMonth() === now.getMonth();
    };

    useEffect(() => {
        if (excludeAccounts !== "null" && localStorage.getItem("exclude_accounts") !== String(excludeAccounts)) {
            const timer = setTimeout(() => {
                sessionStorage.setItem('elapsedTime', '0');
                setStatistics(null);
            }, 1000);

            return () => clearTimeout(timer);
        }
    }, [excludeAccounts]);

    useEffect(() => {
        (async () => {
            if (refresh || (!statistics && users.length)) {
                setStatistics(null);
                sessionStorage.setItem('elapsedTime', '0');
                setRefresh(false);

                const jiraResponse = await getStatistics(excludeAccounts);
                const tempoResponse = await getMonthWorklogs(statsDate);
                const filteredMonthWorklogs = tempoResponse.data?.results.filter(worklog => worklog.attributes.values[0].value !== "TIF");
                let hoursAvailable;

                // Past team billable target is automatically saved monthly
                if(!isCurrentMonthAndYear(statsDate)) {
                    const pastValues = await getPastValues(statsDate);

                    const teamBillableTarget = pastValues.data.rows.filter(item => item.name === "team_billable_target");

                    if(teamBillableTarget.length) {
                        hoursAvailable = {
                            success: pastValues.success,
                            total: teamBillableTarget[0].value,
                        };
                    } else {
                        hoursAvailable = {
                            success: pastValues.success,
                            total: 0,
                        };
                    }
                } else {
                    hoursAvailable = await getHoursAvailable();
                }
    
                // General statistics
                if (tempoResponse.success && jiraResponse.success && hoursAvailable.success) {
                    const { ticketCount, newTickets, ticketLimbo, whatsHot, onHold, unassigned, customerReview } = jiraResponse.data;
                    const monthBilledTime = filteredMonthWorklogs.reduce((acc, worklog) => acc + worklog.billableSeconds, 0);

                    setStatistics({
                        time: {
                            hoursAvailable: hoursAvailable.total,
                            billed: (monthBilledTime / 3600).toFixed(1),
                        },
                        ticketCount: {
                            open: ticketCount[0].total,
                            inProgress: ticketCount[1].total,
                            customerReview: ticketCount[2].total,
                            total: ticketCount.reduce((acc, ticket) => acc + ticket.total, 0),
                        },
                        newTickets: newTickets[0],
                        ticketLimbo: ticketLimbo[0],
                        unassigned: unassigned[0],
                        whatsHot: whatsHot[0],
                        onHold: onHold[0],
                        customerReview: customerReview[0],
                    });
                }
    
                let timeOffUsers = users.filter(user => user.pto?.length).map(user => ({
                    name: user.name,
                    avatarUrl: user.avatar_url,
                    pto: filteredOutsideMonth(user.pto, statsDate),
                })).filter(user => user.pto?.length);
    
                setCurrentMonthUsersTimeOff(timeOffUsers);
            }
        })();
    }, [refresh, statistics, users]);

    useEffect(() => {
        if (initialMount.current) { // Prevent re-render from page change
            initialMount.current = false;
            return;
        }

        setRefresh(true);
    }, [statsDate]);

    return (
        <>
            {
                statistics ?
                    <>
                        <Group className="tif-mb-15" wrap="nowrap" align="flex-start">
                            <Group align="flex-start" className="flex-grow-1 flex-column">
                                <Group gap="15px" align="flex-start">
                                    <MonthPickerInput
                                        value={statsDate}
                                        onChange={value => isCurrentMonth(value) ? setStatsDate(getCurrentDate()) : setStatsDate(value)}
                                        leftSection={<IconCalendarMonth style={{ width: 18, height: 18 }} stroke={1.5} />}
                                        maxDate={getCurrentDate()}
                                    />
                                    <PTOCalendar />
                                </Group>
                                <Text size="sm" className="tif-ls-50 text-uppercase">
                                    Team Stats
                                </Text>
                            </Group>
                            <div className="flex-shrink-0">
                                <UpdateStatus />
                                <Switch
                                    checked={excludeAccounts === "true"}
                                    onChange={() => onExcludeAccountsChange(prevState => prevState === "true" ? "false" : "true")}
                                    size="sm"
                                    label={excludeAccounts === "true" ? "Billable Only" : "Total"}
                                    labelPosition="left"
                                    className="d-flex justify-content-end"
                                />
                            </div>
                        </Group>
                        <SimpleGrid cols={{ base: 1, md: 2 }} className="tif-mb-30">
                            <div className="d-flex flex-column">
                                <Text size="lg" className="tif-mb-10 fw-bold">
                                    Time <span className="tif-font-xs fw-normal">(by month)</span>
                                </Text>
                                <SimpleGrid className="h-100" cols={{ base: 1, md: 3 }}>{timeStats}</SimpleGrid>
                            </div>
                            <div>
                                <Text size="lg" className="tif-mb-10 fw-bold">
                                    Ticket Count
                                </Text>
                                <SimpleGrid cols={{ base: 1, md: 1 }}>
                                    <Paper withBorder p="md" radius="md" className="ticket-count-stats">
                                        <Group align="flex-start">
                                            <div>
                                                <Group justify="space-between">
                                                    <Group align="flex-end" gap="xs">
                                                        <Text fz="xl" fw={700}>
                                                            <NumberFormatter thousandSeparator value={statistics.ticketCount.total} />
                                                        </Text>
                                                    </Group>
                                                    <IconDeviceAnalytics size="1.5rem" className="icon" stroke={1.5} />
                                                </Group>

                                                <Text c="dimmed" fz="sm" className="text-uppercase">
                                                    Total Tickets
                                                </Text>
                                            </div>
                                            <div className="flex-grow-1">
                                                <Progress.Root size={34} classNames={{ label: "progressLabel" }}>
                                                    {segments}
                                                </Progress.Root>
                                                <SimpleGrid cols={{ base: 1, xs: 3 }} mt="xl">
                                                    {descriptions}
                                                </SimpleGrid>
                                            </div>
                                        </Group>
                                    </Paper>
                                </SimpleGrid>
                            </div>
                        </SimpleGrid>
                        <Text size="lg" className="tif-mb-10 fw-bold">
                            Tickets Overview
                        </Text>
                        <SimpleGrid cols={{ base: 1, md: 3 }} className="tif-mb-40">
                            <Card title="What's Hot" Icon={IconFlame} count={statistics.whatsHot.total}>
                                {
                                    <>
                                        {listTickets(statistics ? statistics.whatsHot.issues : [])}
                                        <Link to={`https://thinkitfirst.atlassian.net/issues/?jql=workratio%20>%20100%20AND%20issuetype%20NOT%20IN%20%28Epic%2C%20"TIF%20Team%20Task"%29%20AND%20status%20NOT%20IN%20%28Done%2C%20Deployed%2C%20Cancelled%29${getExcludedAccountsString(excludeAccounts)}%20ORDER%20BY%20workratio%20DESC`} className="btn-anchor tif-mt-auto tif-mx-auto" target="_blank">See All</Link>
                                    </>
                                }
                            </Card>
                            <Card title="New Tickets" Icon={IconCirclePlus}>
                                {
                                    <>
                                        {listTickets(statistics ? statistics.newTickets.issues : [])}
                                        {statistics.newTickets.issues?.length ? <Link to={`https://thinkitfirst.atlassian.net/issues/?jql=issuetype%20NOT%20IN%20%28Epic%2C%20"TIF%20Team%20Task"%29${getExcludedAccountsString(excludeAccounts)}%20ORDER%20BY%20created%20DESC`} className="btn-anchor tif-mt-auto tif-mx-auto" target="_blank">See All</Link> : <></>}
                                    </>
                                }
                            </Card>
                            <Card title="Ticket Limbo" Icon={IconQuestionMark} count={statistics.ticketLimbo.total}>
                                {
                                    <>
                                        {listTickets(statistics ? statistics.ticketLimbo.issues : [])}
                                        {statistics.ticketLimbo.issues?.length ? <Link to={`https://thinkitfirst.atlassian.net/issues/?jql=updated%20<%3D%20-12w%20AND%20status%20NOT%20IN%20%28Cancelled%2C%20Deployed%2C%20Done%29%20AND%20issuetype%20NOT%20IN%20%28Epic%2C%20"TIF%20Team%20Task"%29${getExcludedAccountsString(excludeAccounts)}%20ORDER%20BY%20priority%20DESC%2C%20created%20DESC`} className="btn-anchor tif-mt-auto tif-mx-auto" target="_blank">See All</Link> : <></>}
                                    </>
                                }
                            </Card>
                            <Card title="Unassigned" Icon={IconUserOff} count={statistics.unassigned.total}>
                                {
                                    <>
                                        {listTickets(statistics ? statistics.unassigned.issues : [])}
                                        {statistics.unassigned.issues?.length ? <Link to={`https://thinkitfirst.atlassian.net/issues/?jql=status%20NOT%20IN%20%28Done%2C%20Deployed%2C%20Cancelled%29%20AND%20issuetype%20NOT%20IN%20%28"TIF%20Team%20Task"%2C%20Epic%29%20AND%20assignee%20IS%20EMPTY%20AND%20status%20%21%3D%20"On%20Hold"${getExcludedAccountsString(excludeAccounts)}%20ORDER%20BY%20priority%20DESC%2C%20created%20DESC`} className="btn-anchor tif-mt-auto tif-mx-auto" target="_blank">See All</Link> : <></>}
                                    </>
                                }
                            </Card>
                            <Card title="On Hold" Icon={IconClockStop} count={statistics.onHold.total}>
                                {
                                    <>
                                        {listTickets(statistics ? statistics.onHold.issues : [])}
                                        {statistics.onHold.issues?.length ? <Link to={`https://thinkitfirst.atlassian.net/issues/?jql=issuetype%20NOT%20IN%20%28Epic%2C%20"TIF%20Team%20Task"%29%20AND%20status%20%3D%20"On%20Hold"${getExcludedAccountsString(excludeAccounts)}%20ORDER%20BY%20updated%20DESC`} className="btn-anchor tif-mt-auto tif-mx-auto" target="_blank">See All</Link> : <></>}
                                    </>
                                }
                            </Card>
                            <Card title="Customer Review" Icon={IconClockSearch} count={statistics.customerReview.total}>
                                {
                                    <>
                                        {listTickets(statistics ? statistics.customerReview.issues : [])}
                                        {statistics.customerReview.issues?.length ? <Link to={`https://thinkitfirst.atlassian.net/issues/?jql=issuetype%20NOT%20IN%20%28Epic%2C%20"TIF%20Team%20Task"%29%20AND%20status%20%3D%20"Client%20Review"${getExcludedAccountsString(excludeAccounts)}%20ORDER%20BY%20updated%20DESC`} className="btn-anchor tif-mt-auto tif-mx-auto" target="_blank">See All</Link> : <></>}
                                    </>
                                }
                            </Card>
                        </SimpleGrid>
                    </>
                    :
                    <>
                        {/* PTO Calendar & Update Status */}
                        <SimpleGrid cols={{ base: 1, md: 2 }} className="tif-mb-15">
                            <Skeleton height={50} width={1250} radius="md" />
                            <Skeleton height={20} width={100} radius="md" className="tif-ml-auto" />
                        </SimpleGrid>
                        {/* Team Stats Title */}
                        <SimpleGrid cols={{ base: 1, md: 2 }} className="tif-mb-15">
                            <Skeleton height={20} width={90} radius="md" />
                        </SimpleGrid>
                        {/* Time Counts Title */}
                        <SimpleGrid cols={{ base: 1, md: 2 }} className="tif-mb-30">
                            <Skeleton height={28} width={100} radius="md" />
                            <Skeleton height={28} width={120} radius="md" />
                        </SimpleGrid>
                        {/* Time Counts Grid */}
                        <SimpleGrid cols={{ base: 1, md: 2 }} className="tif-mb-30">
                            <SimpleGrid cols={{ base: 1, md: 3 }}>
                                <Skeleton height={155} radius="md" />
                                <Skeleton height={155} radius="md" />
                                <Skeleton height={155} radius="md" />
                            </SimpleGrid>
                            <SimpleGrid cols={{ base: 1, md: 1 }}>
                                <Skeleton height={155} radius="md" />
                            </SimpleGrid>
                        </SimpleGrid>
                        {/* Ticket Overview Title */}
                        <Skeleton height={28} width={120} radius="md" className="tif-mb-10" />
                        {/* Ticket Overview Grid */}
                        <SimpleGrid cols={{ base: 1, md: 3 }} className="tif-mb-40">
                            <Skeleton height={485} radius="md" />
                            <Skeleton height={485} radius="md" />
                            <Skeleton height={485} radius="md" />
                            <Skeleton height={485} radius="md" />
                            <Skeleton height={485} radius="md" />
                            <Skeleton height={485} radius="md" />
                        </SimpleGrid>
                    </>
            }
        </>
    );
};

export default Statistics;