import {
    Text,
    SimpleGrid,
} from '@mantine/core';
import {
    IconClockQuestion,
    IconUsers,
    IconClockPlay,
    IconCircleCheck,
    IconClockSearch,
    IconClockX,
    IconFlame,
    IconClockExclamation,
    IconEyeCode,
} from '@tabler/icons-react';
import { filteredOutsideMonthHours, listTickets } from '../helpers';
import Card from './Card';
import { Link } from 'react-router-dom';
import {
    getNetworkDays,
    firstMonthDay,
    today,
} from '../helpers';
import { getExcludedAccountsString } from '../../data/dataApi';

const IndividualStatContent = ({ user, excludeAccounts }) => {
    const hoursAvailable = (getNetworkDays() * user.daily_billable_goal) - (user.pto ? filteredOutsideMonthHours(user.pto) : 0);

    return (
        <>
            <Text size="lg" className="tif-mb-10 fw-bold">
                Time <span className="tif-font-xs fw-normal">(by month)</span>
            </Text>
            <div className="stat-panel tif-mb-30">
                <div className="stat-container">
                    <Text className="stat-count">{hoursAvailable}</Text>
                    <Text className="stat-title">Billable Target</Text>
                </div>
                <div className="stat-container">
                    <Text className="stat-count">{user.time.billed}</Text>
                    <Text className="stat-title">Billed</Text>
                </div>
                <div className="stat-container">
                    <Text className="stat-count">{`${Math.round((((user.time.billed) * (getNetworkDays() / getNetworkDays(firstMonthDay, today))) / hoursAvailable) * 100)}%`}</Text>
                    <Text className="stat-title">Tracking</Text>
                </div>
                <div className="stat-container">
                    <Text className="stat-count">{user.time.previousLoggedDays}</Text>
                    <Text className="stat-title">Days Since Logged</Text>
                </div>
                <div className="stat-container">
                    <Text className="stat-count">{user.time.yesterdayBillable}</Text>
                    <Text className="stat-title">Yesterday's Billable</Text>
                </div>
                <div className="stat-container">
                    <Text className="stat-count">{user.time.yesterdayUnbillable}</Text>
                    <Text className="stat-title">Yesterday's Unbillable</Text>
                </div>
            </div>
            <SimpleGrid cols={{ base: 1, md: 4 }} className="tif-mb-40">
                <Card title="Open Tickets" Icon={IconClockQuestion} count={user.openTickets.total}>
                    {listTickets(user.openTickets.issues, false, true, true)}
                    {user.openTickets.issues?.length ? <Link to={`https://thinkitfirst.atlassian.net/issues/?jql=assignee%20%3D%20${user.jira_account_id}%20AND%20statuscategory%20%3D%20New%20AND%20status%20%21%3D%20"On%20Hold"${getExcludedAccountsString(excludeAccounts)}%20ORDER%20BY%20created%20DESC`} className="btn-anchor tif-mt-auto tif-mx-auto" target="_blank">See All</Link> : <></>}
                </Card>
                <Card title="In Progress Tickets" Icon={IconClockPlay} count={user.inProgressTickets.total}>
                    {listTickets(user.inProgressTickets.issues, false, true, true)}
                    {user.inProgressTickets.issues?.length ? <Link to={`https://thinkitfirst.atlassian.net/issues/?jql=assignee%20%3D%20${user.jira_account_id}%20AND%20statuscategory%20%3D%20"In%20Progress"%20AND%20status%20%21%3D%20"Client%20Review"${getExcludedAccountsString(excludeAccounts)}%20ORDER%20BY%20created%20DESC`} className="btn-anchor tif-mt-auto tif-mx-auto" target="_blank">See All</Link> : <></>}
                </Card>
                <Card title="Code Review Tickets" Icon={IconEyeCode} count={user.codeReviewTickets.total}>
                    {listTickets(user.codeReviewTickets.issues, false, true, true)}
                    {user.codeReviewTickets.issues?.length ? <Link to={`https://thinkitfirst.atlassian.net/issues/?jql=assignee%20%3D%20${user.jira_account_id}%20AND%20status%20%3D%20"Code%20Review"${getExcludedAccountsString(excludeAccounts)}%20ORDER%20BY%20created%20DESC`} className="btn-anchor tif-mt-auto tif-mx-auto" target="_blank">See All</Link> : <></>}
                </Card>
                <Card title="Internal Review Tickets" Icon={IconClockSearch}  count={user.internalReviewTickets.total}>
                    {listTickets(user.internalReviewTickets.issues, false, true, true)}
                    {user.internalReviewTickets.issues?.length ? <Link to={`https://thinkitfirst.atlassian.net/issues/?jql=status%20%3D%20"Internal%20Review"%20AND%20assignee%20%3D%20${user.jira_account_id}${getExcludedAccountsString(excludeAccounts)}%20ORDER%20BY%20created%20DESC`} className="btn-anchor tif-mt-auto tif-mx-auto" target="_blank">See All</Link> : <></>}
                </Card>
                <Card title="Customer Review Tickets" Icon={IconClockSearch}  count={user.customerReviewTickets.total}>
                    {listTickets(user.customerReviewTickets.issues, false, true, true)}
                    {user.customerReviewTickets.issues?.length ? <Link to={`https://thinkitfirst.atlassian.net/issues/?jql=status%20%3D%20"Client%20Review"%20AND%20assignee%20%3D%20${user.jira_account_id}${getExcludedAccountsString(excludeAccounts)}%20ORDER%20BY%20created%20DESC`} className="btn-anchor tif-mt-auto tif-mx-auto" target="_blank">See All</Link> : <></>}
                </Card>
                <Card title="Done Tickets" Icon={IconCircleCheck} count={user.doneTickets.total}>
                    {listTickets(user.doneTickets.issues, false, true, true)}
                    {user.doneTickets.issues?.length ? <Link to={`https://thinkitfirst.atlassian.net/issues/?jql=assignee%20%3D%20${user.jira_account_id}%20AND%20statuscategory%20%3D%20Complete${getExcludedAccountsString(excludeAccounts)}%20ORDER%20BY%20created%20DESC`} className="btn-anchor tif-mt-auto tif-mx-auto" target="_blank">See All</Link> : <></>}
                </Card>
                <Card title="High Priority" Icon={IconClockExclamation} count={user.highPriorityTickets.total}>
                    {listTickets(user.highPriorityTickets.issues, false, true, true)}
                    {user.highPriorityTickets.issues?.length ? <Link to={`https://thinkitfirst.atlassian.net/issues/?jql=priority%20>%20Medium%20AND%20issuetype%20NOT%20IN%20%28Epic%2C%20"TIF%20Team%20Task"%29%20AND%20status%20NOT%20IN%20%28Done%2C%20Deployed%2C%20Cancelled%29${getExcludedAccountsString(excludeAccounts)}%20AND%20assignee%20%3D%20${ user.jira_account_id }%20ORDER%20BY%20created%20DESC`} className="btn-anchor tif-mt-auto tif-mx-auto" target="_blank">See All</Link> : <></>}
                </Card>
                <Card title="What's Hot" Icon={IconFlame} count={user.whatsHotTickets.total}>
                    {listTickets(user.whatsHotTickets.issues, false, true, true)}
                    {user.whatsHotTickets.issues?.length ? <Link to={`https://thinkitfirst.atlassian.net/issues/?jql=workratio%20>%20100%20AND%20issuetype%20NOT%20IN%20%28Epic%2C%20"TIF%20Team%20Task"%29%20AND%20status%20NOT%20IN%20%28Done%2C%20Deployed%2C%20Cancelled%29${getExcludedAccountsString(excludeAccounts)}%20AND%20assignee%20%3D%20${user.jira_account_id}%20ORDER%20BY%20workratio%20DESC`} className="btn-anchor tif-mt-auto tif-mx-auto" target="_blank">See All</Link> : <></>}
                </Card>
                <Card title="Empty Time Estimate" Icon={IconClockX} count={user.emptyTimeEstimateTickets.total}>
                    {listTickets(user.emptyTimeEstimateTickets.issues, false, true, true)}
                    {user.emptyTimeEstimateTickets.issues?.length ? <Link to={`https://thinkitfirst.atlassian.net/issues/?jql=originalestimate%20IS%20empty%0AAND%20issuetype%20NOT%20IN%20%28Epic%2C%20"TIF%20Team%20Task"%29%0AAND%20status%20NOT%20IN%20%28Done%2C%20Deployed%2C%20Cancelled%29%0AAND%20assignee%20%3D%20${user.jira_account_id}${getExcludedAccountsString(excludeAccounts)}%0AORDER%20BY%20created%20DESC`} className="btn-anchor tif-mt-auto tif-mx-auto" target="_blank">See All</Link> : <></>}
                </Card>
                <Card title="Top Clients" Icon={IconUsers}>
                    {listTickets(user.recentClients, true, false)}
                </Card>
            </SimpleGrid>
        </>
    )
};

export default IndividualStatContent;